import React, { Component } from 'react';

import videojs from "video.js";
import 'aframe';
import 'aframe-html-shader';

import Footer from '../footer/footer-page'
import zoomInBtn from '../images/medexIcon/zoom-in.png';
import zoomOutBtn from '../images/medexIcon/zoom-out.png';
import eventPosterImg from '../images/25th live on banner.jpg';

import participants from '../images/participants.png';
import closebtn from '../images/cross-2.png';
import raisehandicon from '../images/lower hand.png';

import backArrow from '../images/up_arrow.png';
import backArrowLabelImg from '../images/meta-ild-exit-label.jpg';
import webinarPoster from '../images/medexIcon/medex-backdrop.png';
import '../sky/sky.js'
import Header from '../header/header';
import '../functions/helpers/c-zoom-in-out'
import { isMobileDevice } from '../functions/helpers/extra-functions';
// import { StartWebinarMeeting } from '../functions/helpers/webinar-zoom';
import startCameraImg from '../images/medexIcon/camera.png';
import stopCameraImg from '../images/medexIcon/off-cam.png';
import startMicImg from '../images/medexIcon/unmute-med.png';
import stopMicImg from '../images/medexIcon/mute-med.png';
import screenshareImg from '../images/screen-share-ild.png';
import stopScreenshareImg from '../images/no-screen-share-ild.png';
import raiseHandImg from '../images/hand.png';
import cancelRaiseHandImg from '../images/lower hand1.png';
import zoomInLabel from "../images/zoom-in-label.png";
import zoomOutLabel from "../images/zoom-out-label.png";
import fullscreenButton from "../images/full-screen-icon.png";
import fullscreenLabel from "../images/fullscreen-label.png";
import ChatPanel from '../chatpanel/ChatPanel'
import authFirebaseApp from '../functions/features/auth-firebase-app';
// import { StartBreakout1Meeting } from '../functions/helpers/breakout-zoom-1';
import LoadingPage from '../loading/loading-page';
import { analyticsFunction, updateEndTime, updateLastSceneEndTime } from '../functions/helpers/analytics-function';
import './webinar-hall-page.css'
import firebaseOther from '../functions/features/firebase-app';
import dbFirebaseApp from '../functions/features/db-firebase-app';
import analyticsFirebase from '../functions/features/analytics-firebase-app';
import CustomNotification from '../helpers/notification/notification';
import LoadingPageBtn from '../loading/loading-page-btn';
const roomName = 'webinarHall'
const sceneName = 'webinarHall'
const expoName = 'udyog-launch'

const webinarPosterImage = 'https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/angels-events/medex-webinar-backdrop.png'
const webinarSky = 'https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image360/angels/medex-webinar.jpg';

let myCameraFov = false;

let isAudioMuted = true;
let isVideoMuted = true;
let isFirstTimeLoweredHand = true;
let isScreenshareStarted = false;
let raiseHandStatus = false;
const liveFormat = "application/x-mpegURL";
export default class WebinarHallPage extends Component {

    state = {
        isloading: true,
        startAudio: true,
        shouldShowNotification: false,
        notificationMsg: '',
        isBtnShow: false,
        isMarqueeShow: false,
        isLive: false
    }

    componentDidMount() {


        if (localStorage.getItem('userRole') === 'user' && localStorage.getItem('uid') !== '') {
            console.warn('welcome')
        } else {
            window.location.href = '/'
        }
        let self = this;
        window.AFRAME.registerComponent("sky-webinar-component", {
            init: function () {
                let el = this.el;
                el.addEventListener("loaded", (e) => {
                    console.log('sky showed')
                    self.setState({
                        isBtnShow: true,
                    })
                });
            },
        });

        window.addEventListener('beforeunload', this.preventPageUnload)
        // authFirebaseApp.auth().onAuthStateChanged((user) => {
        //     if (user) {
        //         console.warn('user webinar', user)
        //         // ...
        //     }
        //     else {
        //         console.warn('user not found')
        //         window.location.href = '/'
        //         // User is signed out
        //         // ...
        //     }
        // });
        // let iOS = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
        // let isSafari = /(Mac|iPhone|iPod|iPad)/i.test(window.navigator.userAgent) && /WebKit/i.test(window.navigator.userAgent) && !(/(CriOS|FxiOS|OPiOS|mercury)/i.test(window.navigator.userAgent));
        // let isChrome = navigator.userAgent.indexOf("Chrome") > -1;
        // if (iOS) {
        //     let isMac = /(Mac)/i.test(navigator.platform);
        //     if (isMac) {
        //         if (isSafari && !isChrome) {
        //             document.getElementById('appleDeviceRedirectionPopup').style.display = 'flex';
        //         } else if (isChrome) {
        //             // StartWebinarMeeting();
        //         }
        //     } else {
        //         document.getElementById('appleDeviceRedirectionPopup').style.display = 'flex';
        //     }
        // } else {
        //     // StartWebinarMeeting();
        // }
        localStorage.setItem('Location', 'webinar-hall')
        myCameraFov = false;
        document.addEventListener('touchstart', this.onTouchStart);

        window.addEventListener("message", this.projectButtonClick);
        // StartBreakout1Meeting();

        videojs('video-player').src({
            type: liveFormat,
            src: 'https://d1cuqcohvmxzrq.cloudfront.net/stream/index.m3u8',
        });
        videojs('video-player1').src({
            type: liveFormat,
            src: 'https://d1cuqcohvmxzrq.cloudfront.net/stream/index.m3u8',
        });
        videojs('video-player1').volume(0);
        document.getElementById('video-player1').addEventListener('fullscreenchange', (event) => {
            // document.fullscreenElement will point to the element that
            // is in fullscreen mode if there is one. If not, the value
            // of the property is null.
            if (document.fullscreenElement) {
                console.log(`Element: ${document.fullscreenElement.id} entered fullscreen mode.`);
                videojs('video-player1').volume(1);
                videojs('video-player').volume(0);
            } else {
                console.log('Leaving full-screen mode.');
                videojs('video-player1').volume(0);
                videojs('video-player').volume(1);
            }
        });

        // videojs('video-player').play();

        // if (localStorage.getItem('userRole') === 'speaker') {
        //     document.getElementById('screenshareBtn').setAttribute('visible', true);
        //     document.getElementById('screenshareBtn').setAttribute('position', '1.2 -0.9 -10');
        //     document.getElementById('raiseHandIcon').setAttribute('visible', false);
        //     document.getElementById('raiseHandIcon').setAttribute('position', '0 20 0');
        // } else {
        //     if (localStorage.getItem('docRef') && localStorage.getItem('docRef') !== '') {
        //         this.updateRaiseHandDoc(false);
        //     } else {
        //         this.createRaiseHandDoc();
        //     }
        // }
        // this.updateCollection();
        updateLastSceneEndTime(roomName);

        document.getElementById('mainDiv').innerHTML = '';
        this.getUserDetails();
    }

    createRaiseHandDoc = () => {
        // Add a new document with a generated id.
        firebaseOther.firestore().collection("MetaILDLiveUser").add({
            name: localStorage.getItem('name'),
            sceneName: localStorage.getItem('Location'),
            uid: localStorage.getItem('uid'),
            isRaisedHand: 'false'
        })
            .then(function (docRef) {
                console.log("Document written with ID: ", docRef.id);
                localStorage.setItem('raiseHandDocID', docRef.id);
            })
            .catch(function (error) {
                console.error("Error adding document: ", error);
            });
    }

    updateRaiseHandDoc = (myRaiseHandStatus) => {
        let self = this;
        let raiseHandDocRef = firebaseOther.firestore().collection("MetaILDLiveUser").doc(localStorage.getItem('docRef'));

        raiseHandDocRef.update({
            isRaisedHand: myRaiseHandStatus.toString()
        })
            .then(function () {
                raiseHandStatus = myRaiseHandStatus;
                console.log("Document successfully updated!");
                if (myRaiseHandStatus === true) {
                    document.getElementById('raiseHandIcon').setAttribute('src', '#raiseHand');
                    self.setState({ notificationMsg: 'You have raised your hand', shouldShowNotification: true });
                    setTimeout(() => {
                        self.setState({ shouldShowNotification: false });
                    }, 5000);
                } else {
                    document.getElementById('raiseHandIcon').setAttribute('src', '#cancelRaiseHand');
                    if (isFirstTimeLoweredHand === false) {
                        self.setState({ notificationMsg: 'You have lowered your hand', shouldShowNotification: true });
                        setTimeout(() => {
                            self.setState({ shouldShowNotification: false });
                        }, 5000);
                    } else {
                        isFirstTimeLoweredHand = false;
                    }
                }
            })
            .catch(function (error) {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
            });
    }

    openparticipant = () => {
        document.getElementById("partcipant").classList.toggle("bottomtotopparticipant");
    }

    // getUserDetails() {
    //     const db = dbFirebaseApp.firestore();
    //     const userCollection = db.collection('MetaILDLiveUser');
    //     userCollection.where('sceneName', '==', 'webinarHall').orderBy('isRaisedHand', 'desc')
    //         .onSnapshot(function (querySnapshot) {
    //             querySnapshot.docChanges().forEach(function (change) {
    //                 console.warn('change data is ::', change.doc.data())
    //                 if (change.type === "added" && change.type !== "modified") {
    //                     console.log("New city: ", change.doc.data());
    //                     let myData = change.doc.data();
    //                     if (change.doc.data().poster === 'true') {
    //                         document.getElementById('posterForWeibinar').setAttribute('visible', 'true')
    //                         document.getElementById('webinarVideo').setAttribute('visible', 'false')
    //                     } else if (change.doc.data().poster === 'false') {
    //                         document.getElementById('posterForWeibinar').setAttribute('visible', 'false')
    //                         document.getElementById('webinarVideo').setAttribute('visible', 'true')
    //                     }
    //                 }
    //                 if (change.type === "modified") {
    //                     console.log("Modified city: ", change.doc.data());
    //                     if (change.doc.data().poster === 'true') {

    //                     } else if (change.doc.data().poster === 'false') {

    //                     }
    //                 }
    //                 if (change.type === "removed" && change.type !== "modified") {
    //                     console.log("Removed city: ", change.doc.data());

    //                 }
    //                 // console.log(doc.id, " => ", doc.data());

    //             });

    //             // resolve(profilePicArray);

    //         });

    // }

    getUserDetails() {
        let self = this;
        const db = dbFirebaseApp.firestore();
        const userCollection = db.collection('MedexLive');
        // let ExistingUserQuery = new Promise((resolve, reject) => {
        let profilePicArray = [];
        userCollection.where('expo', '==', expoName)
            .onSnapshot(function (querySnapshot) {
                querySnapshot.docChanges().forEach(function (change) {

                    if (change.doc.data().poster === 'true') {

                        document.getElementById('posterForWeibinar').setAttribute('visible', 'true')
                        document.getElementById('webinarVideo').setAttribute('visible', 'false')
                        document.getElementById('Full-Btn').setAttribute('visible', 'false')
                        document.getElementById('Full-Label').setAttribute('visible', 'false')
                        document.getElementById('Full-Btn').setAttribute('position', '0 200 0')
                        document.getElementById('Full-Label').setAttribute('position', '0 200 0')
                        // if (change.doc.data().isLive === 'true') {
                        //     self.setState({ isLive: true })
                        //     if (navigator.vendor != null && navigator.vendor.match(/Apple Computer, Inc./) && navigator.userAgent.match(
                        //         /iPhone/i) || (navigator.userAgent.match(/iPod/i))) {
                    
                        //         // alert("Ipod or Iphone");
                        //         setTimeout(() => {
                        //             document.getElementById('iphonePopup').style.display = 'block'
                        //         }, 10000);
                    
                        //     } else {
                        //         console.warn('not an iphone')
                        //     }
                        // } else if (change.doc.data().isLive === 'false') {
                        //     self.setState({ isLive: false })
                        // }
                        if (change.doc.data().marquee === 'true') {
                            self.setState({ isMarqueeShow: true })
                            console.warn('marquee state is ::', self.state.isMarqueeShow)
                        } else if (change.doc.data().marquee === 'false') {
                            self.setState({ isMarqueeShow: false })
                            console.warn('marquee state is ::', self.state.isMarqueeShow)
                        }
                    } else if (change.doc.data().poster === 'false') {
                        document.getElementById('posterForWeibinar').setAttribute('visible', 'false')
                        document.getElementById('webinarVideo').setAttribute('visible', 'true')
                        document.getElementById('Full-Btn').setAttribute('visible', 'true')
                        document.getElementById('Full-Label').setAttribute('visible', 'true')
                        document.getElementById('Full-Btn').setAttribute('position', '8 2.3 -8')
                        document.getElementById('Full-Label').setAttribute('position', '8 1.6 -8')
                        // if (change.doc.data().isLive === 'true') {
                        //     self.setState({ isLive: true })
                        //     if (navigator.vendor != null && navigator.vendor.match(/Apple Computer, Inc./) && navigator.userAgent.match(
                        //         /iPhone/i) || (navigator.userAgent.match(/iPod/i))) {
                    
                        //         // alert("Ipod or Iphone");
                        //         setTimeout(() => {
                        //             document.getElementById('iphonePopup').style.display = 'block'
                        //         }, 10000);
                    
                        //     } else {
                        //         console.warn('not an iphone')
                        //     }
                        // } else if (change.doc.data().isLive === 'false') {
                        //     self.setState({ isLive: false })
                        // }
                        if (change.doc.data().marquee === 'true') {
                            self.setState({ isMarqueeShow: true })
                            console.warn('marquee state is ::', self.state.isMarqueeShow)
                        } else if (change.doc.data().marquee === 'false') {
                            self.setState({ isMarqueeShow: false })
                            console.warn('marquee state is ::', self.state.isMarqueeShow)
                        }
                    }
                    if (change.type === "modified") {
                        console.log("Modified city: ", change.doc.data());
                        // if (change.type === "added" && change.type !== "modified") {
                        console.log("New city: ", change.doc.data());
                        if (change.doc.data().poster === 'true') {
                            document.getElementById('posterForWeibinar').setAttribute('visible', 'true')
                            document.getElementById('webinarVideo').setAttribute('visible', 'false')
                            document.getElementById('Full-Btn').setAttribute('visible', 'false')
                            document.getElementById('Full-Label').setAttribute('visible', 'false')
                            document.getElementById('Full-Btn').setAttribute('position', '0 200 0')
                            document.getElementById('Full-Label').setAttribute('position', '0 200 0')
                            // if (change.doc.data().isLive === 'true') {
                            //     self.setState({ isLive: true })
                            //     if (navigator.vendor != null && navigator.vendor.match(/Apple Computer, Inc./) && navigator.userAgent.match(
                            //         /iPhone/i) || (navigator.userAgent.match(/iPod/i))) {
                        
                            //         // alert("Ipod or Iphone");
                            //         setTimeout(() => {
                            //             document.getElementById('iphonePopup').style.display = 'block'
                            //         }, 10000);
                        
                            //     } else {
                            //         console.warn('not an iphone')
                            //     }
                            // } else if (change.doc.data().isLive === 'false') {
                            //     self.setState({ isLive: false })
                            // }
                            if (change.doc.data().marquee === 'true') {
                                self.setState({ isMarqueeShow: true })
                            } else if (change.doc.data().marquee === 'false') {
                                self.setState({ isMarqueeShow: false })
                            }
                        } else if (change.doc.data().poster === 'false') {
                            document.getElementById('posterForWeibinar').setAttribute('visible', 'false')
                            document.getElementById('webinarVideo').setAttribute('visible', 'true')
                            document.getElementById('Full-Btn').setAttribute('visible', 'true')
                            document.getElementById('Full-Label').setAttribute('visible', 'true')
                            document.getElementById('Full-Btn').setAttribute('position', '8 2.3 -8')
                            document.getElementById('Full-Label').setAttribute('position', '8 1.6 -8')
                            // if (change.doc.data().isLive === 'true') {
                            //     self.setState({ isLive: true })
                            //     if (navigator.vendor != null && navigator.vendor.match(/Apple Computer, Inc./) && navigator.userAgent.match(
                            //         /iPhone/i) || (navigator.userAgent.match(/iPod/i))) {
                        
                            //         // alert("Ipod or Iphone");
                            //         setTimeout(() => {
                            //             document.getElementById('iphonePopup').style.display = 'block'
                            //         }, 10000);
                        
                            //     } else {
                            //         console.warn('not an iphone')
                            //     }
                            // } else if (change.doc.data().isLive === 'false') {
                            //     self.setState({ isLive: false })
                            // }
                            if (change.doc.data().marquee === 'true') {
                                self.setState({ isMarqueeShow: true })
                            } else if (change.doc.data().marquee === 'false') {
                                self.setState({ isMarqueeShow: false })
                            }
                        }

                        // }
                    }

                    // console.log(doc.id, " => ", doc.data());

                });

                // resolve(profilePicArray);

            });

    }


    // updateCollection = () => {
    //     const db = dbFirebaseApp.firestore();
    //     db.collection("MetaILDLiveUser").doc(localStorage.getItem('docRef')).update({
    //         sceneName: sceneName,
    //     })
    //         .then(function (docRef) {
    //             console.log("Document written with ID: ", docRef);
    //             // currentDoc = docRef;
    //         })
    //         .catch(function (error) {
    //             console.error("Error adding document: ", error);
    //         });
    // }

    // updateLastSceneEndTime = () => {
    //     console.warn('update last scene end time')
    //     const db = analyticsFirebase.firestore();
    //     db.collection("MEDEXLiveUsers").doc(localStorage.getItem('analyticsDocRef')).update({
    //         endTime: new Date().getTime(),
    //     })
    //         .then(function (docRef) {
    //             console.log("Document written with ID: ", localStorage.getItem('analyticsDocRef'));
    //             // currentDoc = docRef;
    //              analyticsFunction(roomName)
    //             setInterval(() => {
    //                 updateEndTime();
    //             }, 15000);
    //         })
    //         .catch(function (error) {
    //             console.error("Error adding document: ", error);
    //         });
    // }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.preventPageUnload)
        document.removeEventListener('touchstart', this.onTouchStart);
        document.removeEventListener('message', this.projectButtonClick);
    }

    preventPageUnload = (event) => {

        // Cancel the event as stated by the standard.
        event.preventDefault();
        // Older browsers supported custom message
        event.returnValue = 'Are you sure you want to leave webinar hall?';

    }

    projectButtonClick = (e) => {
        console.log('called on webinar image click', e.data);
        if (e.data.isImageClicked) {
            if (e.data.mslKey === 'expo.virtualpropexpo2020.com/lobby') {
                this.goBackToLobby();
            }
        }
    }

    goBackToLobby = () => {
        window.location.href = '/entrance'
    }

    onTouchStart = (event) => {
        if (isMobileDevice() && myCameraFov === false) {
            if (document.querySelector('#webinarCamera')) {
                document.querySelector('#webinarCamera').setAttribute('fov', '79');
                setTimeout(() => {
                    document.querySelector('#webinarCamera').setAttribute('fov', '80');
                }, 100);
                myCameraFov = true;
            }
        }
    }




    toggleCameraView = () => {
        // console.warn('Change Camera View');
        if (document.querySelector('#webinarCamera').getAttribute('fov') === '62') {
            // console.warn('Near');
            document.querySelector('#webinarCamera').setAttribute('fov', '80');
            document.getElementById("Zoom-In").setAttribute('visible', 'true')
            document.getElementById("Zoom-In-Label").setAttribute('visible', 'true')
            document.getElementById("Zoom-Out").setAttribute('visible', 'false')
            document.getElementById("Zoom-Out-Label").setAttribute('visible', 'false')
        } else {
            // console.warn('Far');
            document.querySelector('#webinarCamera').setAttribute('fov', '62');
            document.getElementById("Zoom-Out").setAttribute('visible', 'true')
            document.getElementById("Zoom-Out-Label").setAttribute('visible', 'true')
            document.getElementById("Zoom-In").setAttribute('visible', 'false')
            document.getElementById("Zoom-In-Label").setAttribute('visible', 'false')
        }
    }

    toggleMicMute = () => {
        let audioBtn = document.querySelector('.join-audio-container__btn');
        console.warn('area label is', audioBtn.getAttribute('aria-label'))

        if (document.querySelector('.join-audio-container__btn')) {
            document.querySelector('.join-audio-container__btn').click();
        }
    }

    dynamicAudioIconChange = () => {
        setInterval(() => {
            let audioBtn = document.querySelector('.join-audio-container__btn');
            if (audioBtn) {
                // console.warn('area label is', audioBtn.getAttribute('aria-label'))
                if (audioBtn.getAttribute('aria-label') === 'unmute my microphone') {
                    document.getElementById('audioBtn').setAttribute('src', '#micMuteImg')
                }
                else if (audioBtn.getAttribute('aria-label') === 'mute my microphone') {
                    document.getElementById('audioBtn').setAttribute('src', '#micUnmuteImg')
                }
            } else {
                console.warn('audio btn not found')
            }


        }, 1000);

    }

    dynamicCameraIconChange = () => {
        setInterval(() => {
            let videoBtn = document.querySelector('.send-video-container__btn')
            // console.warn('area label of camera is ::', videoBtn.getAttribute('aria-label'))

            if (videoBtn.getAttribute('aria-label') === 'start sending my video') {
                document.getElementById('videoBtn').setAttribute('src', '#cameraUnmuteImg')
            }
            else if (videoBtn.getAttribute('aria-label') === 'stop sending my video') {
                document.getElementById('videoBtn').setAttribute('src', '#cameraMuteImg')
            }
        }, 1000);
    }

    toggleCameraMute = () => {
        let videoBtn = document.querySelector('.send-video-container__btn')
        console.warn('area label of camera is ::', videoBtn.getAttribute('aria-label'))
        if (document.querySelector('.send-video-container__btn')) {
            document.querySelector('.send-video-container__btn').click();
        }
        if (videoBtn.getAttribute('aria-label') === 'start sending my video') {
            document.getElementById('videoBtn').setAttribute('src', '#cameraUnmuteImg')
        }
        else if (videoBtn.getAttribute('aria-label') === 'stop sending my video') {
            document.getElementById('videoBtn').setAttribute('src', '#cameraMuteImg')
        }

        // isVideoMuted = !isVideoMuted;
        // document.getElementById('videoBtn').setAttribute('src', isVideoMuted ? '#cameraMuteImg' : '#cameraUnmuteImg');
    }

    toggleScreenshare = () => {
        if (isScreenshareStarted) {
            this.stopScreenshare();
        } else {
            this.startScreenshare();
        }
    }

    startScreenshare = () => {
        if (document.getElementsByClassName('footer-button__button')[1]) {
            document.getElementsByClassName('footer-button__button')[1].click();
            setTimeout(() => {
                this.toggleCameraMute();
            }, 15000);
        }
        isScreenshareStarted = !isScreenshareStarted;

        document.getElementById('screenshareBtn').setAttribute('src', isScreenshareStarted ? '#stopScreenshareImg' : '#screenshareImg');
    }

    stopScreenshare = () => {
        if (document.querySelector('.sharer-button--pattern1')) {
            document.querySelector('.sharer-button--pattern1').click();
            // isScreenshareStarted = false;
        }
        isScreenshareStarted = !isScreenshareStarted;
        document.getElementById('screenshareBtn').setAttribute('src', isScreenshareStarted ? '#stopScreenshareImg' : '#screenshareImg');

    }

    toggleRaiseHand = () => {
        if (raiseHandStatus === true) {
            this.updateRaiseHandDoc(false);
        } else {
            this.updateRaiseHandDoc(true);
        }
    }

    joinAudio = () => {
        if (document.getElementsByClassName('join-audio-by-voip__join-btn')[0]) {
            document.getElementsByClassName('join-audio-by-voip__join-btn')[0].click();
        }
        if (document.querySelector('.join-audio-by-voip__join-btn')) {
            document.querySelector('.join-audio-by-voip__join-btn').click();
        }
        document.getElementById('joinAudioPopup').style.display = 'none';
        document.getElementsByClassName('sharee-container__viewport')[0].removeAttribute('style');
        setTimeout(() => {
            document.getElementById('display-box').removeAttribute('src');
            document.getElementById('display-box').setAttribute('color', 'white');
            document.getElementById('display-box').setAttribute('material', 'shader: html; target: #zmmtg-root; fps: 10');
            this.dynamicAudioIconChange();
            // this.dynamicCameraIconChange();
        }, 1000);

    }

    joinUsingZoomLink = () => {
        window.open('https://zoom.us/j/98635820417?pwd=cUdNS09kSTR2V3pLTkg1UDA5R3d2dz09', '_blank');
    }

    changeStateForLoader = (value) => {
        // document.getElementById('video-player').play();

        videojs('video-player').play();
        videojs('video-player1').play();
        videojs('video-player1').volume(0);

        
        this.setState({ isloading: value })


        // videojs('video-player1').src({
        //     type: liveFormat,
        //     src: 'https://d1cuqcohvmxzrq.cloudfront.net/stream/index.m3u8',
        // });
        // videojs('video-player1').play();
    }


    openFullscreen = () => {

        let elem = document.getElementById("video-player1");
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
            /* Firefox */
            elem.mozRequestFullScreen();
        } else if (elem.webkitEnterFullscreen) {
            elem.webkitEnterFullscreen();
        }
        else if (elem.webkitRequestFullscreen) {
            /* Chrome, Safari and Opera */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
            /* IE/Edge */
            elem.msRequestFullscreen();
        }
        videojs('video-player1').volume(1);
    }

    closeFullscreen = () => {

        let elem = document.getElementById("video_player1");

        if (elem.exitFullscreen) {
            elem.exitFullscreen();
        } else if (elem.mozCancelFullScreen) {
            /* Firefox */
            elem.mozCancelFullScreen();
        } else if (elem.webkitExitFullscreen) {
            /* Chrome, Safari and Opera */
            elem.webkitExitFullscreen();
        } else if (elem.msExitFullscreen) {
            /* IE/Edge */
            elem.msExitFullscreen();
        } else if (elem.webkitSupportsFullscreen) {
            elem.webkitExitFullscreen();
        }
    }

    closePopup = () => {
        document.getElementById('iphonePopup').style.display = 'none'
    }

    goTo2dWebinar = () => {
        console.warn('2dwebinar')
        // window.open('2dVideo.html', '_blank');
        window.location.href = '/webinar'

    }


    render() {
        return (
            <div>
                {this.state.isLive &&
                    <div className="overlayVideo" id="iphonePopup" style={{ display: 'none' }}>
                        <div className="PopupIphone" >
                            <button onClick={this.closePopup} className="closebutton" >X</button><br></br>
                            <p className="popuptext">If you face any issues while watching the live session,</p>
                            <p className="popuptext">Please click on the button below.</p>
                            <button onClick={this.goTo2dWebinar} className="rightbtn startExperianceBtn heightExperiance">View in 2D</button>
                        </div>
                    </div>}

                {this.state.isMarqueeShow &&
                    <marquee className="marquee-bottom">You can ask your questions in the ASK YOUR QUESTIONS box</marquee>}
                {/* {this.state.isloading && <LoadingPage />} */}
                {this.state.isloading &&
                    <LoadingPageBtn updateStateForLoader={this.changeStateForLoader} btnStatus={this.state.isBtnShow} />}

                {this.state.shouldShowNotification && <CustomNotification msg={this.state.notificationMsg} />}

                <Header headerType='user' />

                <div id="joinAudioPopup" className='AudioJoinPopup' style={{ display: 'none' }}>
                    <h5>Please join webinar using your device audio</h5>
                    <br />
                    <div id="preparationText" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>Preparing to join webinar &nbsp;&nbsp;
                        <div className="loaderBtn"></div>
                    </div>
                    <button onClick={this.joinAudio} className="joinBtn" id="myJoinWebinarBtn" style={{ display: 'none' }}>Join Webinar</button>
                </div>

                <div id="appleDeviceRedirectionPopup" className='AppleDevicePopup' style={{ display: 'none' }}>
                    <h5>Please join the Webinar on Chrome browser via a laptop/desktop or join the webinar externally.</h5>
                    <br />
                    <button onClick={this.joinUsingZoomLink} className="joinBtn" id="myJoinWebinarBtn" >Join Webinar</button>
                </div>

                <div className="participantposition" >

                    <div className="presentationmoderator" id="partcipant" style={{ left: '1rem' }} >
                        <div className="chat-headparticipants">
                            <div>
                                <h2>Participants</h2>
                                <img src={closebtn} onClick={this.openparticipant} />
                            </div>
                            {/* <img src="https://maxcdn.icons8.com/windows10/PNG/16/Arrows/angle_down-16.png" title="Expand Arrow" width="16" onClick={this.Togglechatbox} /> */}
                        </div>
                        <div className="chat-bodymes scrolltype" id="myScrollingParticipantList" style={{ height: '245px' }}>
                            <div className="flexform  " id="mainDiv" >
                                <div className="single-message flexparti" >
                                    <div className="name">Participant-1</div>
                                    <img src={raisehandicon} className="raisehandwidth"></img>
                                </div>
                            </div>

                        </div>
                    </div>


                </div>
                <video
                    id="video-player1"
                    className="video-js vjs-default-skin video2dHtmlEl"
                    crossOrigin="anonymous"
                    loop
                    webkit-playsinline="true"
                    playsInline
                    src="">
                </video>
                <div className="participantsiconpositionmod" onClick={this.openparticipant} style={{ display: 'none' }}>
                    <img src={participants} alt="logout" className=" widthicon marginbottomimg" style={{ display: 'flex' }} data-tooltip="tooltip" data-placement="right" title="Participants" />
                    <div className="icontext textshadow">Participants</div>
                </div>
                <>
                    <a-scene loading-screen="enabled: false" vr-mode-ui="enabled: false" style={{ zIndex: 2 }}>
                        <a-assets>
                            <img id="sky" crossOrigin="anonymous" src={webinarSky} alt="background" />
                            <video
                                id="video-player"
                                className="video-js vjs-default-skin video2dHtmlEl"
                                crossOrigin="anonymous"
                                loop
                                webkit-playsinline="true"
                                playsInline
                                src="">
                            </video>
                            <img id="micMuteImg" src={stopMicImg} alt="Stop Mic" />
                            <img id="micUnmuteImg" src={startMicImg} alt="Start Mic" />
                            <img id="cameraMuteImg" src={stopCameraImg} alt="Stop Camera" />
                            <img id="cameraUnmuteImg" src={startCameraImg} alt="Start Camera" />
                            <img id="screenshareImg" src={screenshareImg} alt="ss" />
                            <img id="raiseHand" src={raiseHandImg} alt="raiseHandIcon" />
                            <img id="cancelRaiseHand" src={cancelRaiseHandImg} alt="CancelRaiseHandIcon" />
                            <img id="stopScreenshareImg" src={stopScreenshareImg} alt="sss" />
                            <img id="backArrowwebinar" src={backArrow} alt='back' />
                            <img id="backArrowLabel" src={backArrowLabelImg} alt='back' />
                            <img id="zoomInBtn" src={zoomInBtn} alt="Zoom In Button" />
                            <img id="zoomInLabel" src={zoomInLabel} alt="Zoom In Label" />
                            <img id="zoomOutBtn" src={zoomOutBtn} alt="Zoom Out Button" />
                            <img id="zoomOutLabel" src={zoomOutLabel} alt="Zoom Out Label" />
                            <img id="fullScreenBtn" src={fullscreenButton} alt="FullScreen Button" />
                            <img id="fullScreenLabel" src={fullscreenLabel} alt="FullScreen Label" />
                            <img id="webinarPoster" src={webinarPosterImage} crossOrigin="anonymous" alt="Event Poster" />
                        </a-assets>
                        <a-camera c-zoom-in-out="defaultFov: 80" look-controls="magicWindowTrackingEnabled: false; gyroModeEnabled: false; reverseTouchDrag: true; disableVerticalMotion:true;" id="webinarCamera" fov="80" wasd-controls="enabled:false">
                            <a-entity
                                cursor="rayOrigin: mouse; fuse: false; disableDragClick: true"
                                raycaster="objects: .raycaster-solid; recursive: false;"
                            />
                        </a-camera>
                        <a-sky sky-webinar-component id="bgImage" src="#sky" rotation="0 -10 0" ></a-sky>
                        <a-image id="myPresentation" src="" position="2.65 2.5 -21.9" scale="1 1 1" width="17" height="9" visible={false}></a-image>
                        <a-image src="#backArrowwebinar" id="webinar-back" position="-2.3 0.9 5.35" rotation="0 0 0" scale="0.35 0.35 0.35" class="raycaster-solid clickable" onClick={this.goBackToLobby}></a-image>
                        <a-image src="#backArrowwebinar" id="webinar-back2" position="2.3 0.9 5.35" rotation="0 0 0" scale="0.35 0.35 0.35" class="raycaster-solid clickable" onClick={this.goBackToLobby}></a-image>
                        {/* <a-image src="#backArrowLabel" id="webinar-back-label" position="-3.5 1.5 -2.35" rotation="0 90 0" scale="1 0.3 0.35" ></a-image> */}
                        <a-image src="#fullScreenBtn" id="Full-Btn" position="8 2.3 -8" rotation="0 -5 0" height="1" width="1" class="raycaster-solid clickable" onClick={this.openFullscreen} />
                        <a-image src="#fullScreenLabel" id="Full-Label" position="8 1.6 -8" rotation="0 -5 0" height="0.22" width="1.5" class="raycaster-solid clickable" onClick={this.openFullscreen} />
                        <a-image src="#zoomInBtn" id="Zoom-In" position="8 0.7 -8" rotation="0 -5 0" height="1" width="1" class="raycaster-solid clickable" onClick={this.toggleCameraView} />
                        <a-image src="#zoomInLabel" id="Zoom-In-Label" position="8 0 -8" rotation="0 -5 0" height="0.25" width="1.2" class="raycaster-solid clickable" onClick={this.toggleCameraView} />
                        <a-image src="#zoomOutBtn" id="Zoom-Out" position="8 0.7 -8" rotation="0 -5 0" height="1" width="1" class="raycaster-solid clickable" onClick={this.toggleCameraView} visible={false} />
                        <a-image src="#zoomOutLabel" id="Zoom-Out-Label" position="8 0 -8" rotation="0 -5 0" height="0.25" width="1.2" class="raycaster-solid clickable" onClick={this.toggleCameraView} visible={false} />
                        {/* <a-image src="#micMuteImg" id="audioBtn" position="-1.2 -0.9 -10" rotation="0 -5 0" scale="1 1 1" height="1" width="1" class="raycaster-solid clickable" onClick={this.toggleMicMute} />
                        <a-image src="#cameraMuteImg" id="videoBtn" position="0 -0.9 -10" rotation="0 -5 0" scale="1 1 1" height="1" width="1" class="raycaster-solid clickable" onClick={this.toggleCameraMute} />
                        <a-image src="#cancelRaiseHand" id="raiseHandIcon" position="1.2 -0.9 -10" rotation="0 -5 0" scale="1 1 1" height="1" width="1" class="raycaster-solid clickable" onClick={this.toggleRaiseHand} />
                        <a-image src="#screenshareImg" id="screenshareBtn" visible='false' position="0 20 0" rotation="0 -5 0" scale="1 1 1" height="1" width="1" class="raycaster-solid clickable" onClick={this.toggleScreenshare} /> */}
                        {/* <a-image src="#webinarPoster" id="display-box" visible={true} position="-0.1 2.3 -10" scale="11.15 5.4 1" class="raycaster-solid clickable" onClick={this.toggleCameraView} /> */}
                        <a-image id="posterForWeibinar" src="#webinarPoster" position="-0.1 3.5 -10" scale="18 8 1" visible={false} ></a-image>
                        <a-video id="webinarVideo" look-at="webinarCamera" src="#video-player_html5_api" position="-0.1 3.5 -10" scale="18 8 1" class="raycaster-solid clickable" onClick={this.toggleCameraView} />
                    </a-scene>
                </>
                <ChatPanel roomName="webinarUdyogLaunch" />
                <Footer />
            </div>
        )
    }
}
