import React, { Component } from "react";
import Footer from "../footer/footer-page";
import Header from "../header/header";
import startCameraImg from "../images/medexIcon/camera.png";
import stopCameraImg from "../images/medexIcon/off-cam.png";
import CaptureImg from "../images/medexIcon/capture.png";
import loginFirebaseApp from "../functions/features/login-firebase-app";
import firebaseOther from "../functions/features/firebase-app";
import webinarLabel from "../images/WEBINAR HALL-LABEL.png";
import breakoutlabel from "../images/BREAKOUT ROOMS-LABEL.png";
import upArrow from "../images/up_arrow.png";
import frameImg from "../images/FRAME.png";
import playBtn from "../images/play.png";
import pauseBtn from "../images/pause.png";
import playLabel from "../images/play-label.png";
import pauseLabel from "../images/pause-label.png";
import exitLabel from "../images/exit-label.png";
import videoPlay from "../images/medexIcon/play-btn.png";
import playPauseLabel from "../images/medexIcon/play-pause.png";
import agendaBtn from "../images/medexIcon/agenda2.png";
import click from "../images/click.png";
import right from "../images/medexIcon/swipe right.png";
import left from "../images/medexIcon/swipe left.png";
import leftImg from "../images/leftGifImg.png";
import rightImg from "../images/merz-selfie-booth.png";
import "aframe";
import "aframe-gif-shader";
// import 'aframe-extras';
import "aframe-html-shader";
import dbFirebaseApp from "../functions/features/db-firebase-app";
import authFirebaseApp from "../functions/features/auth-firebase-app";
import LoadingPage from "../loading/loading-page";
import analyticsFirebase from "../functions/features/analytics-firebase-app";
import entranceImage from "../images/Reception Lobby1.jpg";
import playImg from "../images/medexIcon/play-med.png";
import pauseImg from "../images/medexIcon/pause-med.png";
import closeBtn from "../images/medexIcon/cross.png";
import videoPosterImg from "../images/emma-video-poster.png";
import whiteImg from "../images/white.png";
import pollImg from "../images/lobby-speaker-stand.png";
import logoImg from "../images/logo.png";
import sloganImg from "../images/merz-slogan.png";
// import facultyLabelImg from '../images/faculty-label.png'
import swipeLeftImg from "../images/swipe-left.png";
import swipeRightImg from "../images/swipe-right.png";
import CustomNotification from "../helpers/notification/notification";

import {
  analyticsFunction,
  updateEndTime,
  updateLastSceneEndTime,
} from "../functions/helpers/analytics-function";
import myBgBackup from '../images/merz-reception-lobby-new.jpg';
const entranceSrc =
  "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image360/angels/merz-reception-lobby-new.jpg";
const entranceVideo =
  "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/angels/Merz-Emma-Video-final.mp4";
const centerVideoLink =
  "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/angels/merz-lobby-center.mp4";
const leftVideoLink =
  "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/angels/Medex-lobby-left-logo-c.mp4";
const rightVideoLink =
  "https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/angels/Medex-lobby-right-human-c.mp4";
const leftGifLink =
  "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/angels-events/medex-gif1.gif";
let canvasDrawRequest = null;
const sceneName = "entrance";
const roomName = "entrance";
const expoName = 'udyog-launch';
var snapshots = [];
var slideIndex = 0;

let galleryImages = [];
let capturedSelfieImage = "";
let k = 0;
let isVideoPlay = false;
export default class Entrance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCameraStarted: false,
      isloading: true,
      // isBtnLive:false,
    };
    this.videoRef = React.createRef();
    this.inputStreamRef = React.createRef();
  }

  componentDidMount() {
    if (
      localStorage.getItem("userRole") === "user" &&
      localStorage.getItem("uid") !== ""
    ) {
      console.warn("welcome");
    } else {
      window.location.href = "/";
    }
    let self = this;
    window.AFRAME.registerComponent("sky-entrance-component", {
      init: function () {
        let el = this.el;
        el.addEventListener("loaded", (e) => {
          console.log("sky showed");
          self.setState({
            isloading: false,
          });
        });
      },
    });

    // authFirebaseApp.auth().onAuthStateChanged((user) => {
    //     if (user) {
    //         console.warn('entrance', user)
    //         // ...
    //     }
    //     else {
    //         console.warn('user not found')
    //         window.location.href = '/'
    //         // User is signed out
    //         // ...
    //     }
    // });
    // localStorage.setItem('UserName', 'Heaven Test')
    // localStorage.setItem('ProfilePicUrl', 'https://firebasestorage.googleapis.com/v0/b/vr-expo.appspot.com/o/angels%2F530b57d0-c604-98a6-b432-387863af6c1e?alt=media&token=9ee59c1a-0bea-4d49-bf15-9e2a27f17317')
    // localStorage.setItem('uid', Math.random())
    localStorage.setItem("Location", "entrance");
    let slideImagePromise = new Promise((resolve, reject) => {
      firebaseOther
        .firestore()
        .collection("Medex")
        .doc("SelfieZone")
        .get()
        .then((doc) => {
          if (doc.exists) galleryImages = doc.data().merzGalleryImages;
          console.log("gallery images", galleryImages);
          resolve(galleryImages);
        })
        .catch(function (error) {
          console.log(error);
          // Handle Errors here.

          reject(error);
          // ...
        });
    });
    slideImagePromise
      .then((result) => {
        console.warn("Successful", result);
        galleryImages = result;
        this.showSlideImage();
      })
      .catch((error) => {
        console.log("error caught", error);
        console.error(error);
        var errorMessage = error.message;
        // alert(errorMessage)
      });
    // if (localStorage.getItem('docRef')) {
    //     console.warn('not need to entry')
    // } else {
    //     this.addNewEntry();
    // }
    // this.setState({isloading:false})
    // this.updateCollection();
    updateLastSceneEndTime(roomName);

    // setTimeout(() => {
    //     document.getElementById('leftaimg').setAttribute('visible', 'false')
    //     document.getElementById('rightaimg').setAttribute('visible', 'false')
    // }, 20000);

    // this.getUserDetails()
  }

  playVideo1 = () => {
    if (
      document.getElementById("2dVideo2") &&
      document.getElementById("Pause2dVideo2") &&
      document.getElementById("Play2dVideo2")
    ) {
      document.getElementById("2dVideo2").play();
      document.getElementById("2dVideo2").volume = 1;
      document.getElementById("Pause2dVideo2").style.display = "block";
      document.getElementById("Play2dVideo2").style.display = "none";
      // localStorage.setItem('isVideoWatched', 'true')
      // document.getElementById('countdown1').style.display = 'block';
    }
  };

  pauseVideo1 = () => {
    if (
      document.getElementById("2dVideo2") &&
      document.getElementById("Pause2dVideo2") &&
      document.getElementById("Play2dVideo2")
    ) {
      document.getElementById("2dVideo2").pause();

      document.getElementById("2dVideo2").volume = 0;
      document.getElementById("Play2dVideo2").style.display = "block";
      document.getElementById("Pause2dVideo2").style.display = "none";
      // document.getElementById('countdown1').style.display = 'none';
    }
  };

  closeVideoPopup = () => {
    // localStorage.setItem('isVideoWatched', 'true')
    document.getElementById("VideoOverlay2").style.display = "none";

    document.getElementById("video-popup2").style.display = "none";
    document.getElementById("closeBtn2").style.display = "none";
    document.getElementById("2dVideo2").setAttribute("src", "");
  };

  // updateLastSceneEndTime = () => {
  //     console.warn('update last scene end time')
  //     const db = analyticsFirebase.firestore();
  //     db.collection("MEDEXLiveUsers").doc(localStorage.getItem('analyticsDocRef')).update({
  //         endTime: new Date().getTime(),
  //     })
  //         .then(function (docRef) {
  //             console.log("Document written with ID: ", localStorage.getItem('analyticsDocRef'));
  //             // currentDoc = docRef;
  //              analyticsFunction(roomName)
  //             setInterval(() => {
  //                updateEndTime();
  //             }, 15000);
  //         })
  //         .catch(function (error) {
  //             console.error("Error adding document: ", error);
  //         });
  // }

  // updateCollection = () => {
  //     const db = dbFirebaseApp.firestore();
  //     db.collection("MetaILDLiveUser").doc(localStorage.getItem('docRef')).update({
  //         sceneName: sceneName,
  //     })
  //         .then(function (docRef) {
  //             console.log("Document written with ID: ", docRef);
  //             // currentDoc = docRef;
  //         })
  //         .catch(function (error) {
  //             console.error("Error adding document: ", error);
  //         });
  // }

  showSlideImage = () => {
    console.warn("images is :: ", galleryImages);
    for (let i = 0; i < galleryImages.length; i++) {
      let imageElement = document.createElement("img");
      imageElement.setAttribute("crossOrigin", "anonymous");
      imageElement.setAttribute("id", "img" + i);
      imageElement.setAttribute("src", galleryImages[i]);
      document.getElementById("aAssetEntrance").appendChild(imageElement);
      // let divElement = document.createElement('div')
      // let imageElement = document.createElement('img')
      // divElement.setAttribute('class', 'mySlides')
      // divElement.setAttribute('id','div'+i)

      // imageElement.setAttribute('src', galleryImages[i])
      // imageElement.setAttribute('id','image'+i)
      // // imageElement.setAttribute('class','myImage')
      // divElement.appendChild(imageElement)
      // document.getElementById('slideshow-container').appendChild(divElement)
    }
    this.updateSrcAimage();
    // setTimeout(() => {
    //     this.showSlides();
    // }, 1);
  };

  // getUserDetails() {
  //     let self = this;
  //     const db = dbFirebaseApp.firestore();
  //     const userCollection = db.collection('MedexLive');
  //     // let ExistingUserQuery = new Promise((resolve, reject) => {
  //     let profilePicArray = [];
  //     userCollection.where('expo', '==', expoName)
  //         .onSnapshot(function (querySnapshot) {
  //             querySnapshot.docChanges().forEach(function (change) {
  //                 if (change.type === "added" && change.type !== "modified") {
  //                     console.log("New city: ", change.doc.data());
  //                     if(change.doc.data().isLive === 'true'){
  //                         self.setState({isBtnLive:true})
  //                     }
  //                     else if(change.doc.data().isLive === 'false'){
  //                         self.setState({isBtnLive:false})
  //                     }

  //                 }
  //                 if (change.type === "modified") {
  //                     console.log("Modified city: ", change.doc.data());
  //                     // if (change.type === "added" && change.type !== "modified") {
  //                         console.log("New city: ", change.doc.data());
  //                         if(change.doc.data().isLive === 'true'){
  //                             self.setState({isBtnLive:true})
  //                         }
  //                         else if(change.doc.data().isLive === 'false'){
  //                             self.setState({isBtnLive:false})
  //                         }

  //                     // }
  //                 }

  //                 // console.log(doc.id, " => ", doc.data());

  //             });

  //             // resolve(profilePicArray);

  //         });

  // }

  showSlides = () => {
    var slides = document.getElementsByClassName("mySlides");
    for (let i = 0; i < slides.length; i++) {
      var images = document.getElementById("image" + i);
    }
    console.warn("image src is ::", images.src);
    if (slides) {
      for (let i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
      slideIndex++;
      if (slideIndex > slides.length) {
        slideIndex = 1;
      }
      for (let i = 0; i < slides.length; i++) {
        slides[i].className = slides[i].className.replace(" active", "");
      }
      if (slides[slideIndex - 1]) {
        slides[slideIndex - 1].style.display = "block";
        slides[slideIndex - 1].className += " active";
        this.drawCanvasforSlideShow(
          document.getElementById("image" + (slideIndex - 1))
        );
      }

      setTimeout(this.showSlides, 2000); // Change image every 2 seconds
    }
  };

  updateSrcAimage = () => {
    // for (let i = 0; i < galleryImages.length; i++) {
    // let i=0
    let slideShowElement = document.getElementById("slide-show");
    if (slideShowElement) {
      slideShowElement.setAttribute("src", "#img" + k);
      k++;
      if (k === galleryImages.length) {
        k = 0;
      }
      // }
      setTimeout(() => {
        this.updateSrcAimage();
      }, 2000);
    }
    let slideShowElement2 = document.getElementById("slide-show2");
    if (slideShowElement2) {
      slideShowElement2.setAttribute("src", "#img" + k);

      // }
    }
  };

  async startCamera() {
    try {
      const CAMERA_CONSTRAINTS = {
        // audio: true,
        video: true,
      };
      this.inputStreamRef.current = await navigator.mediaDevices.getUserMedia(
        CAMERA_CONSTRAINTS
      );

      this.videoRef.current.srcObject = this.inputStreamRef.current;

      await this.videoRef.current.play();

      this.setState({ isCameraStarted: true });
      document.getElementById("slide-show").setAttribute("visible", false);
      document.getElementById("selfieDisplay").setAttribute("visible", true);
      document.getElementById("captureBtn").setAttribute("visible", true);
      document
        .getElementById("captureBtn")
        .setAttribute("position", "8.5 3 0.8");
      document.getElementById("stopcameraBtn").setAttribute("visible", true);
      document
        .getElementById("stopcameraBtn")
        .setAttribute("position", "8.5 3 -0.3");
      document.getElementById("startCameraBtn").setAttribute("visible", false);
      document
        .getElementById("startCameraBtn")
        .setAttribute("position", "0 200 0");
      // this.startStreaming();
      // setTimeout(() => {
      if (this.isMobileDevice()) {
        console.warn("mobile device");
        this.drawCanvasForMobile();
      } else {
        this.drawCanvas();
      }
      // }, 5000);
    } catch (e) {
      console.warn("Camera start error", e);
      this.setState({
        shouldShowNotification: true,
        notificationMsg: "Could not start the camera !",
      });
      setTimeout(() => {
        this.setState({ shouldShowNotification: false });
      }, 6000);
    }
  }

  drawCanvas = () => {
    var canvas = document.getElementById("selfieCanvas");
    var video = document.getElementById("videoselfie");
    if (canvas && video) {
      canvas.width = 1490;
      canvas.height = 873;

      var ctx = canvas.getContext("2d");
      let whiteImage = new Image();
      whiteImage.src = whiteImg;
      ctx.drawImage(whiteImage, 0, 0, 1490, 873);
      ctx.drawImage(video, 104, 0, 1280, 720);
      let myImage = new Image();
      myImage.src = frameImg;
      // setTimeout(() => {
      ctx.drawImage(myImage, 0, 0, 1490, 873);
    }
    // }, 10);
    canvasDrawRequest = requestAnimationFrame(this.drawCanvas);
  };

  drawCanvasForMobile = () => {
    var canvas = document.getElementById("selfieCanvas");
    var video = document.getElementById("videoselfie");
    if (canvas && video) {
      canvas.width = 1490;
      canvas.height = 873;
      let w = video.width;
      var ctx = canvas.getContext("2d");
      let whiteImage = new Image();
      whiteImage.src = whiteImg;
      ctx.drawImage(whiteImage, 0, 0, 1490, 873);
      ctx.drawImage(video, 444, 0, 600, 873);
      let myImage = new Image();
      myImage.src = frameImg;
      // setTimeout(() => {
      ctx.drawImage(myImage, 0, 0, 1490, 873);
    }
    // }, 10);
    canvasDrawRequest = requestAnimationFrame(this.drawCanvasForMobile);
  };

  drawCanvasforSlideShow = (image) => {
    console.warn("image in function is ", image);
    var canvas = document.getElementById("slide-Canvas");
    // var video = document.getElementById('videoselfie');
    if (canvas) {
      canvas.width = 500;
      canvas.height = 300;
      var ctx = canvas.getContext("2d");

      let myImage = new Image();
      myImage.src = image.src;
      myImage.crossOrigin = "";
      // setTimeout(() => {
      ctx.drawImage(myImage, 0, 0, 500, 300);
    }
    // }, 10);
  };

  stopCamera() {
    console.warn("Stop Camera");
    if (this.inputStreamRef.current !== null) {
      this.inputStreamRef.current.getTracks().forEach((track) => {
        track.stop();
      });
    }
    // this.videoRef.current.srcObject = '';
    this.setState({ isCameraStarted: false });
    document.getElementById("selfieDisplay").setAttribute("visible", false);
    document.getElementById("captureBtn").setAttribute("visible", false);
    document.getElementById("captureBtn").setAttribute("position", "0 200 0");
    document.getElementById("stopcameraBtn").setAttribute("visible", false);
    document
      .getElementById("stopcameraBtn")
      .setAttribute("position", "0 200 0");
    document.getElementById("startCameraBtn").setAttribute("visible", true);
    document
      .getElementById("startCameraBtn")
      .setAttribute("position", "8.5 3 0.2");
    document.getElementById("slide-show").setAttribute("visible", true);
  }

  captureCamera() {
    console.warn("capture camera");
    var video = document.getElementById("videoselfie");
    var output = document.getElementById("output");
    var canvas = document.createElement("canvas");
    canvas.setAttribute("id", "shootCanvas");
    canvas.width = 1488;
    canvas.height = 872;
    var ctx = canvas.getContext("2d");
    let whiteImage = new Image();
    whiteImage.src = whiteImg;
    ctx.drawImage(whiteImage, 0, 0, 1488, 872);
    ctx.drawImage(video, 104, 0, 1280, 720);
    let myImage = new Image();
    myImage.src = frameImg;

    const guid = () => {
      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
      }
      return String(
        s4() +
        s4() +
        "-" +
        s4() +
        "-" +
        s4() +
        "-" +
        s4() +
        "-" +
        s4() +
        s4() +
        s4()
      );
    };
    setTimeout(() => {
      ctx.drawImage(myImage, 0, 0, 1488, 872);
      snapshots.unshift(canvas);
      output.innerHTML = "";
      for (var i = 0; i < 4; i++) {
        output.appendChild(snapshots[0]);
      }
    }, 10);

    setTimeout(() => {
      canvas.toBlob(function (blob) {
        console.log(blob);

        let myPromise = new Promise((resolve, reject) => {
          const myGuid = guid();
          const storageUrl = loginFirebaseApp.storage(
            "gs://vr-expo.appspot.com/"
          );
          const storageRef = storageUrl.ref();

          const uploadTask = storageRef
            .child("angels")
            .child("merz")
            .child(myGuid)
            .put(blob);
          uploadTask.on(
            "state_changed",
            (snapShot) => { },
            (err) => {
              //catches the errors
              console.log(err);
              reject(err);
            },
            () => {
              loginFirebaseApp
                .storage("gs://vr-expo.appspot.com/")
                .ref()
                .child("angels")
                .child("merz")
                .child(myGuid)
                .getDownloadURL()
                .then((fireBaseUrl) => {
                  resolve(fireBaseUrl);
                })
                .catch((err) => {
                  console.log("error caught", err);
                });
            }
          );
        });
        myPromise
          .then((url) => {
            console.log(url);

            capturedSelfieImage = url;
            // this.saveSelefieData()
            galleryImages = [...galleryImages, capturedSelfieImage];

            console.log("gallary images", galleryImages);
            console.log("captured selfie images", capturedSelfieImage);
            firebaseOther
              .firestore()
              .collection("Medex")
              .doc("SelfieZone")
              .update({
                merzGalleryImages: galleryImages,
              });
          })
          .catch((err) => {
            console.log("error caught", err);
          });

        // this line should be here
      }, "image/png");
    }, 1000);

    setTimeout(() => {
      var downloadLink = document.getElementById("downloader");
      downloadLink.click();
    }, 10);

    // this.downloadImage();
  }

  captureCameraForMobile() {
    console.warn("capture camera");
    var video = document.getElementById("videoselfie");
    var output = document.getElementById("output");
    var canvas = document.createElement("canvas");
    canvas.setAttribute("id", "shootCanvas");
    canvas.width = 1488;
    canvas.height = 872;
    var ctx = canvas.getContext("2d");
    let whiteImage = new Image();
    whiteImage.src = whiteImg;
    ctx.drawImage(whiteImage, 0, 0, 1488, 872);
    ctx.drawImage(video, 444, 0, 600, 872);
    let myImage = new Image();
    myImage.src = frameImg;

    const guid = () => {
      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
      }
      return String(
        s4() +
        s4() +
        "-" +
        s4() +
        "-" +
        s4() +
        "-" +
        s4() +
        "-" +
        s4() +
        s4() +
        s4()
      );
    };
    setTimeout(() => {
      ctx.drawImage(myImage, 0, 0, 1488, 872);
      snapshots.unshift(canvas);
      output.innerHTML = "";
      for (var i = 0; i < 4; i++) {
        output.appendChild(snapshots[0]);
      }
    }, 10);

    setTimeout(() => {
      canvas.toBlob(function (blob) {
        console.log(blob);

        let myPromise = new Promise((resolve, reject) => {
          const myGuid = guid();
          const storageUrl = loginFirebaseApp.storage(
            "gs://vr-expo.appspot.com/"
          );
          const storageRef = storageUrl.ref();

          const uploadTask = storageRef
            .child("angels")
            .child("merz")
            .child(myGuid)
            .put(blob);
          uploadTask.on(
            "state_changed",
            (snapShot) => { },
            (err) => {
              //catches the errors
              console.log(err);
              reject(err);
            },
            () => {
              loginFirebaseApp
                .storage("gs://vr-expo.appspot.com/")
                .ref()
                .child("angels")
                .child("merz")
                .child(myGuid)
                .getDownloadURL()
                .then((fireBaseUrl) => {
                  resolve(fireBaseUrl);
                })
                .catch((err) => {
                  console.log("error caught", err);
                });
            }
          );
        });
        myPromise
          .then((url) => {
            console.log(url);

            capturedSelfieImage = url;
            // this.saveSelefieData()
            galleryImages = [...galleryImages, capturedSelfieImage];

            console.log("gallary images", galleryImages);
            console.log("captured selfie images", capturedSelfieImage);
            firebaseOther
              .firestore()
              .collection("Medex")
              .doc("SelfieZone")
              .update({
                merzGalleryImages: galleryImages,
              });
          })
          .catch((err) => {
            console.log("error caught", err);
          });

        // this line should be here
      }, "image/png");
    }, 1000);

    setTimeout(() => {
      var downloadLink = document.getElementById("downloader");
      downloadLink.click();
    }, 10);

    // this.downloadImage();
  }

  togglePlayPauseVideo = () => {
    if (isVideoPlay) {
      this.pauseVideo();
      isVideoPlay = false;
    } else {
      this.playVideo();
      // isVideoPlay = true;
    }
  };

  loadBackupImage = () =>{
    document.getElementById('myBGSky').src = myBgBackup;
  }

  playVideo = () => {
    document.getElementById("entranceVideoCenter").play();
    document.getElementById("playBtn").setAttribute("visible", "false");
    document
      .getElementById("playBtn")
      .setAttribute("position", "-0.2 2.65 -4.95");
    isVideoPlay = true;
    // document.getElementById('pauseBtn').setAttribute('visible', 'true');
    // document.getElementById('pauseLabel1').setAttribute('visible', 'true');
    // document.getElementById('playLabel1').setAttribute('visible', 'false');
    // document.getElementById('pauseBtn').setAttribute('position', '-0.3 2.25 -5.5');
  };

  pauseVideo = () => {
    document.getElementById("entranceVideoCenter").pause();
    // document.getElementById('pauseBtn').setAttribute('visible', 'false');
    // document.getElementById('pauseLabel1').setAttribute('visible', 'false');
    // document.getElementById('playLabel1').setAttribute('visible', 'true');
    // document.getElementById('pauseBtn').setAttribute('position', '0 20 0');
    document.getElementById("playBtn").setAttribute("visible", "true");
    document
      .getElementById("playBtn")
      .setAttribute("position", "-0.2 2.65 -4.95");
  };

  downloadImage = () => {
    document.getElementById("downloader").download = "image.png";
    document.getElementById("downloader").href = document
      .getElementById("shootCanvas")
      .toDataURL("image/png")
      .replace(/^data:image\/[^;]/, "data:application/octet-stream");
  };
  goToBreakOut = () => {
    window.location.href = "/breakout-page";
  };

  goToWebinar = () => {
    window.location.href = "/webinar";
  };

  goToDisplayGallery = () => {
    window.location.href = "/product-launch";
  };

  goToCountry = () => {
    window.location.href = "/country";
  };

  goToArchive = () => {
    window.location.href = "/archive";
  };

  goToExterior = () => {
    window.location.href = "/exterior";
  };

  goToEntertainment = () => {
    window.location.href = "/entertainment-zone";
  };

  goToMedexFaculty = () => {
    window.location.href = "/faculty-room";
  };

  goToAgenda = () => {
    document.getElementById("location1").style.display = "block";
  };

  goToT2d = () => {
    window.open(
      "https://pro.boehringer-ingelheim.com/across-t2d/request-access",
      "_blank"
    );
  };

  goToHf = () => {
    window.open(
      "https://pro.boehringer-ingelheim.com/across-hf/request-access",
      "_blank"
    );
  };

  isMobileDevice = () => {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    return (
      <div>
        {this.state.isloading && <LoadingPage />}
        <Header headerType="user" />

        {/* {localStorage.getItem('isVideoWatched') !== 'true' &&
                    <div>
                        <div className="overlayVideo" id="VideoOverlay2" >
                            <div id="countdown1" style={{ display: 'none', position: 'fixed', top: '30px', width: '70px', textAlign: 'center', left: '50%', transform: 'translate(-50%, -50%)', color: 'white', padding: '3px 10px', border: '2px solid white', borderRadius: '5px' }}> <span></span> </div>

                        </div>

                        <div id="video-popup2">

                            <button id="closeBtn2" className="closebtnposition" onClick={this.closeVideoPopup} style={{ outline: 'none' }}>
                                <img src={closeBtn} alt="play" className="closebtnwidth"></img>
                            </button>
                            <p className="videoTitle">Guided Tour by Emma</p>
                            <video id="2dVideo2" className="videopopup" playsInline crossOrigin="anonymous" src={entranceVideo} poster={videoPosterImg} preload="auto" ></video>
                            <button id="Play2dVideo2" onClick={this.playVideo1} style={{ outline: 'none' }}>
                                <img src={playImg} alt="play" className="playbtnwidth"></img>
                                <p className="playvideotext">Play Guided Tour</p>
                            </button>
                            <button id="Pause2dVideo2" style={{ display: 'none', outline: 'none' }} onClick={this.pauseVideo1} >
                                <img src={pauseImg} alt="play" className="playbtnwidth"></img>
                                <p className="playvideotext">Pause Guided Tour</p>

                            </button>
                            <button id="startExperienceButton1" style={{ display: 'none' }} className="startExperianceBtn heightExperiance" onClick={this.startExperiance} >Enter Event</button>


                        </div>
                    </div>} */}
        <video
          id="videoselfie"
          ref={this.videoRef}
          autoPlay
          playsInline
          muted
          style={{ position: "fixed", top: "0px", left: "0px" }}
        ></video>
        {/* <video id="modelVideo" autoPlay playsInline muted crossOrigin="anonymous" src="https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/angels/Angels-Promo-Video.mp4"></video> */}
        <div
          id="selfieImage"
          style={{
            width: "100%",
            height: "100%",
            position: "fixed",
            left: "0",
            top: "0",
            zIndex: "-1",
            overflow: "hidden",
          }}
        >
          <canvas
            id="selfieCanvas"
            style={{ width: "100%", height: "100%" }}
          ></canvas>
        </div>
        {/* <div id="slideshowImage" style={{ width: "100%", height: "100%", position: "fixed", left: "0", top: "0", zIndex: "-1", overflow: "hidden" }}>
                        <canvas crossOrigin="anonymous" id="slide-Canvas" style={{ width: '100%' }} ></canvas>
                    </div> */}
        <a
          href="#"
          id="downloader"
          onClick={this.downloadImage}
          download="image.png"
        ></a>
        <br />
        <div id="output"></div>
        {/* {this.state.isBtnLive && <button id="quickAccessBtn"  className="quickbtn">Quick Access to Live Session</button> } */}
        <a-scene
          loading-screen="enabled: false"
          vr-mode-ui="enabled: false"
          style={{ zIndex: 2 }}
        >
          <a-assets id="aAssetEntrance">
            <img
              id="sky"
              crossorigin="anonymous"
              crossOrigin="anonymous"
              src={entranceSrc}
              alt="background"
              onError={this.loadBackupImage}
            />
            <img id="startCamera" src={startCameraImg} alt="upload" />
            <img id="stopCamera" src={stopCameraImg} alt="stop camera" />
            <img id="captureImage" src={CaptureImg} alt="capture" />
            <img id="arrow" src={upArrow} alt="arrow" />
            <img
              id="transparent"
              src="https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/angels-events/merz-up-arrow.png"
              alt="up arrow"
            />
            <img id="webinar-label" src={webinarLabel} alt="webinarLabel" />
            <img id="breakout-label" src={breakoutlabel} alt="breakoutLabel" />
            <img id="agenda-btn" src={agendaBtn} alt="agendabtn" />
            <img id="play-btn" src={videoPlay} alt="playBtn" />
            <img id="pause-btn" src={pauseBtn} alt="pauseBtn" />
            <img id="play-label" src={playPauseLabel} alt="play-label" />
            {/* <img id='pause-label' src={playPauseLabel} alt='pause-label' /> */}
            <img id="exit-label" src={exitLabel} alt="pause-label" />
            <img id="left-gif" src={leftGifLink} alt="gif" />
            <img id="left-gif-text" src={click} alt="click" />
            <img id="swiperight-gif-text" src={swipeRightImg} alt="click" />
            <img id="swipeleft-gif-text" src={swipeLeftImg} alt="click" />
            <img id="rightgif" src={rightImg} alt="rightimg" />
            <img id="leftgif" src={leftImg} alt="leftimg" />
            <img id="poll" src={pollImg} alt="poll" />
            <img id="logo" src={logoImg} alt="merz-logo" />
            <img id="slogan" src={sloganImg} alt="merz-slogan" />
            {/* <img id='facultyLabel' src={facultyLabelImg} alt='merz-faculty' /> */}
            <video
              id="entranceVideoCenter"
              webkit-playsinline="true"
              playsInline
              loop
              preload="auto"
              src={centerVideoLink}
              alt="entrancevideo"
            />
          </a-assets>
          <a-camera
            c-zoom-in-out="defaultFov: 80"
            look-controls="magicWindowTrackingEnabled: false; reverseTouchDrag: true; disableVerticalMotion:true;"
            id="entranceSceneCamera"
            fov="80"
            wasd-controls="enabled:false"
          >
            <a-entity
              cursor="rayOrigin: mouse; fuse: false; disableDragClick: true"
              raycaster="objects: .raycaster-solid; recursive: false;"
            />
          </a-camera>

          <a-sky id="myBGSky" src="#sky" rotation="0 0 0"
            crossorigin="anonymous" onerror={this.loadBackupImage}
            onError={this.loadBackupImage}
            crossOrigin="anonymous" sky-entrance-component></a-sky>
          <a-image
            visible="false"
            id="selfieDisplay"
            position="8.3 0.1 0.2"
            rotation="0 -85 0"
            scale="5.1 2.9 3.1"
            material="shader: html; target: #selfieImage; fps: 10"
          ></a-image>
          <a-image
            id="slide-show"
            position="8.3 0.1 0.2"
            rotation="0 -85 0"
            scale="5.1 2.9 3.1"
          // material="shader: html; target: #slideshowImage; fps: 10"
          ></a-image>
          <a-image
            src="#transparent"
            id="arrowbtn3"
            class="raycaster-solid clickable"
            scale="1.5 1 1.5"
            onClick={this.goToDisplayGallery}
            rotation="0 0 0"
            position="-14.5 -2 -18.808"
          ></a-image>
          <a-image
            src="#transparent"
            id="arrowbtn2"
            class="raycaster-solid clickable"
            scale="1.5 1 1.5"
            onClick={this.goToArchive}
            rotation="0 0 0"
            position="-7.422 -2 -18.808"
          ></a-image>
          <a-image
            src="#transparent"
            id="arrowbtn"
            class="raycaster-solid clickable"
            scale="1.5 1 1.5"
            onClick={this.goToWebinar}
            rotation="0 0 0"
            position="-0.637 -2 -18.808"
          ></a-image>
          <a-image
            src="#transparent"
            id="arrowbtn4"
            class="raycaster-solid clickable"
            scale="1.5 1 1.5"
            onClick={this.goToMedexFaculty}
            rotation="0 0 0"
            position="6.282 -2 -18.808"
          ></a-image>
          <a-image
            src="#transparent"
            id="arrowbtn4"
            class="raycaster-solid clickable"
            scale="1.5 1 1.5"
            onClick={this.goToEntertainment}
            rotation="0 0 0"
            position="13 -2 -18.808"
          ></a-image>
          <a-image
            src="#exit-label"
            id="exit-label1"
            class="raycaster-solid clickable"
            scale="2.4 1.2 1"
            onClick={this.goToExterior}
            rotation="0 180 0"
            position="0.3 3.8 12"
          ></a-image>
          <a-image
            src="#transparent"
            id="arrowbtn3"
            class="raycaster-solid clickable"
            scale="1.2 1.2 1.2"
            onClick={this.goToExterior}
            rotation="60 0 0"
            position="0.3 -1.5 12"
          ></a-image>
          <a-image
            src="#logo"
            lookAt="#entranceSceneCamera"
            id="merz-logo"
            scale="3.2 2 1"
            rotation="0 180 0"
            position="4 1.8 5"
          ></a-image>
          <a-image
            src="#slogan"
            lookAt="#entranceSceneCamera"
            id="merz-slogan"
            scale="3.5 1.8 1"
            rotation="0 180 0"
            position="-4 1.8 5"
          ></a-image>
          {/* <a-image src="#arrow" id="arrowbtn3" class="raycaster-solid clickable" scale="1.5 1.5 1.5" onClick={this.goToEntertainment} rotation='45 -30 0' position="10.25 -0.9 -20"></a-image> */}
          {/* <a-entity
                        id='gif'
                        class="raycaster-solid"
                        geometry={`primitive:plane;width:4.05;height:4.8;`}
                        rotation='0 60 0' position="-15 1.35 -6.6"
                        material="shader:gif;src:url(https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/angels-events/medex-speaker-gif.gif);"
                    />
                    <a-entity
                        id='leftgif'
                        class="raycaster-solid"
                        geometry={`primitive:plane;width:2.5;height:1.4;`}
                        position="-3.7 3 -3.5" rotation="0 25 -1"
                        material="shader:gif;src:url(https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/angels-events/medex-logo-gif1.gif);"
                    />
                    <a-entity
                        id='rightgif'
                        class="raycaster-solid"
                        geometry={`primitive:plane;width:2.25;height:1.3;`}
                        position="3.05 2.9 -3.8" rotation="0 -25 0"
                        material="shader:gif;src:url(https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/angels-events/medex-human-gif1.gif);"
                    /> */}
          {/* <a-image id='leftaimg' src="#leftgif" position="-3.7 3 -3.5" rotation="0 25 -1" width='2.5' height='1.4' ></a-image> */}
          <a-image
            id="rightaimg"
            src="#rightgif"
            position="8.5 0.3 0.2"
            rotation="0 -85 0"
            width="9.25"
            height="4.3"
          ></a-image>
          {/* <a-entity
                        id='arrowGif'
                        class="raycaster-solid"
                        geometry={`primitive:plane;width:1;height:0.5;`}
                        rotation='0 0 0' position="2 0.5 -3"
                        material="shader:gif;src:url(https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/angels-events/medex-arrow.gif);alphaTest:0.5"
                    /> */}
          {/* <a-entity
                        id='arrowGif2'
                        class="raycaster-solid"
                        geometry={`primitive:plane;width:1;height:0.5;`}
                        rotation='0 0 180' position="-2.4 0.5 -3"
                        material="shader:gif;src:url(https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/angels-events/medex-arrow.gif);alphaTest:0.5"
                    /> */}
          <a-image
            id="poll"
            src="#poll"
            position="-17 1 -6"
            rotation="0 60 0"
            width="5.5"
            height="11"
            class="raycaster-solid clickable"
            onClick={() => this.goToMedexFaculty()}
          ></a-image>
          {/* <a-image id='facultyLabel' src="#facultyLabel" rotation='0 60 0' position="-13.5 5.6 -6" width='6' height='1' class="raycaster-solid clickable" onClick={() => this.goToMedexFaculty()} ></a-image> */}
          <a-entity
            id="gif"
            class="raycaster-solid clickable"
            onClick={() => this.goToMedexFaculty()}
            geometry={`primitive:plane;width:3.85;height:5.68;`}
            rotation="0 60 0"
            position="-13 1.3 -4.6"
            material="shader:gif;src:url(https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/angels-events/merz-speakers-gif.gif);"
          />
          <a-image
            src="#swiperight-gif-text"
            id="swiperight-gif-text1"
            scale="1 1 1"
            rotation="0 -20 0"
            position="2.4 0.3 -3"
          ></a-image>
          <a-image
            src="#swipeleft-gif-text"
            id="swipeleft-gif-text1"
            scale="1 1 1"
            rotation="0 20 0"
            position="-2.6 0.3 -3"
          ></a-image>
          {/* <a-image src="#left-gif" id="left-gif1" class="raycaster-solid clickable" scale="3.6 4.5 1.5" rotation='0 60 0' position="-15 1.8 -7"></a-image> */}
          {/* <a-image src="#transparent" id="left-gif-click1" class="raycaster-solid clickable" onClick={this.goToMedexFaculty} scale="1 1 1" opacity='0' height='1.4' width='3.9' rotation='0 60 0' position="-15 -1.9 -6.6"></a-image> */}
          {/* <a-image src="#transparent" id="t2d" class="raycaster-solid clickable" onClick={this.goToT2d} scale="1 1 1" opacity='0' height='0.5' width='1' position="7.5 2.85 1.2" rotation="0 -90 0"></a-image>
                    <a-image src="#transparent" id="hf" class="raycaster-solid clickable" onClick={this.goToHf} scale="1 1 1" opacity='0' height='0.5' width='1' position="7.5 1.65 1.2" rotation="0 -90 0"></a-image> */}
          <a-image
            src="#startCamera"
            id="startCameraBtn"
            position="8.5 3 0.2"
            rotation="0 -90 0"
            scale="0.8 0.8 0.8"
            class="raycaster-solid clickable"
            onClick={() => this.startCamera()}
          ></a-image>
          <a-image
            src="#stopCamera"
            id="stopcameraBtn"
            position="6.9 -0.45 -4"
            visible="false"
            rotation="0 -90 0"
            scale="0.8 0.8 0.8"
            class="raycaster-solid clickable"
            onClick={() => this.stopCamera()}
          ></a-image>
          {this.isMobileDevice() ? (
            <a-image
              src="#captureImage"
              id="captureBtn"
              position="8 -0.55 -2.95"
              visible="false"
              rotation="0 -90 0"
              scale="0.8 0.8 0.8"
              class="raycaster-solid clickable"
              onClick={() => this.captureCameraForMobile()}
            ></a-image>
          ) : (
            <a-image
              src="#captureImage"
              id="captureBtn"
              position="8 -0.55 -2.95"
              visible="false"
              rotation="0 -90 0"
              scale="0.8 0.8 0.8"
              class="raycaster-solid clickable"
              onClick={() => this.captureCamera()}
            ></a-image>
          )}
          {/* <a-image src="#agenda-btn" id="agendaBtn1" scale="1 0.45 1" position="-0.4 -0.85 -5.5" class="raycaster-solid clickable" onClick={() => this.goToAgenda()}></a-image> */}
          <a-image
            src="#play-btn"
            id="playBtn"
            scale="0.35 0.35 0.4"
            position="-0.2 2.65 -4.95"
            class="raycaster-solid clickable"
            onClick={() => this.playVideo()}
          ></a-image>
          {/* <a-image src="#pause-btn" id="pauseBtn" scale="0.35 0.35 0.4" visible="false" position="0 20 0" class="raycaster-solid clickable" onClick={() => this.pauseVideo()}></a-image> */}
          <a-image
            src="#play-label"
            id="playLabel1"
            scale="1.6 0.2 1"
            position="-0.1 3.05 -3.5"
            class="raycaster-solid clickable"
          ></a-image>
          {/* <a-image src="#pause-label" id="pauseLabel1" scale="1.4 0.18 0.35" visible="false" position="-0.3 5 -6.5" class="raycaster-solid clickable" ></a-image> */}
          <a-video
            src="#entranceVideoCenter"
            id="videoEntrance"
            scale="3.4 1.7 1"
            position="-0.15 2.67 -5"
            class="raycaster-solid clickable"
            onClick={() => this.togglePlayPauseVideo()}
          ></a-video>
          {/* <a-video src="#entranceVideoLeft" id='videoEntranceLeft' scale="2.55 1.45 1" position="-3.7 3 -3.5" rotation="0 25 -1"></a-video> */}
          {/* <a-video src="#entranceVideoRight" id='videoEntranceRight' scale="2.3 1.5 1" position="3.05 3 -3.8" rotation="0 -25 0"></a-video> */}
        </a-scene>
        <Footer />
        {this.state.shouldShowNotification && (
          <CustomNotification msg={this.state.notificationMsg} />
        )}
      </div>
    );
  }
}
