import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../helpers/footer/footer.css';
import './agenda-style.css';
import './bootstrap.css';
import lobbyImg from '../images/medexIcon/RECEPTION-LOBBY-MED.png';
import feedbackImg from '../images/medexIcon/feedback.png';
import webinarImg from '../images/medexIcon/WEBINAR-MED.png';
import salesImg from '../images/medexIcon/sales-room.png';
import ArchiveImg from '../images/medexIcon/archive.png';
import displaygallaryImg from '../images/medexIcon/country-room.png';
import entertainmentImg from '../images/medexIcon/entertain-med.png';
import agendaImg from '../images/medexIcon/agenda.png';
import displayGallery from '../images/medexIcon/Display Gallery.png'
import facultyIcon from '../images/medexIcon/merz-faculty.png'
import logout from '../images/medexIcon/logout.png';
import tutorialimg1 from '../images/desktopangels1.jpg';
import tutorialimg2 from '../images/mobangels2.jpg';
import playImg from '../images/medexIcon/play-med.png';
import pauseImg from '../images/medexIcon/pause-med.png';
import closeBtn from '../images/medexIcon/cross.png'
// import videoPosterImg from '../images/emma-video-poster.png';
import menuImg from '../images/menu.png';
import closeImg from '../images/cross.png';

import sabestianImg from '../images/agenda/Sebastian.png';
import mattImg from '../images/agenda/Matt.png';
import mohamedImg from '../images/agenda/Mohamed.png';
import nabilImg from '../images/agenda/Nabil.png';
import naifImg from '../images/agenda/Naif.png';
import ramiImg from '../images/agenda/Rami.png';
import tatjanaImg from '../images/agenda/Tatjana.png';

import loginFirebaseApp from '../functions/features/login-firebase-app';
import authFirebaseApp from '../functions/features/auth-firebase-app';

import dbFirebaseApp from '../functions/features/db-firebase-app';
import emma from '../images/medexIcon/emma.png'
import { updateLastSceneEndTime } from '../functions/helpers/analytics-function';
import analyticsFirebase from '../functions/features/analytics-firebase-app';
import CustomNotification from '../helpers/notification/notification';

const entranceVideo = 'https://pptvr-videos.s3.ap-south-1.amazonaws.com/video/public/video2d/angels/Merz-Emma-Video-final.mp4'
const videoPosterImg = 'https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/angels-events/merz-emma-video-poster.png'
const expoName = 'udyog-launch'

export default class Footer extends Component {

    state = {
        isBtnLive: false,
        isVideoStart: false,
        showProductLaunch: false,
    }

    componentDidMount() {
        this.getUserDetails()

    }


    getUserDetails() {
        let self = this;
        const db = loginFirebaseApp.firestore();
        const userCollection = db.collection('LiveEvents');
        // let ExistingUserQuery = new Promise((resolve, reject) => {
        userCollection.where('expo', '==', expoName)
            .onSnapshot(function (querySnapshot) {
                querySnapshot.docChanges().forEach(function (change) {
                    if (change.type === "added" && change.type !== "modified") {
                        console.log("New city: ", change.doc.data());
                        if (change.doc.data().platformLive === true) {
                            self.setState({ isBtnLive: true })
                        }
                        else if (change.doc.data().platformLive === false) {
                            self.setState({ isBtnLive: false })
                        }
                        if (change.doc.data().showProductLaunch === true) {
                            self.setState({ showProductLaunch: true })
                        }
                        else if (change.doc.data().showProductLaunch === false) {
                            self.setState({ showProductLaunch: false })
                        }

                    }
                    if (change.type === "modified") {
                        console.log("Modified city: ", change.doc.data());
                        // if (change.type === "added" && change.type !== "modified") {
                        console.log("New city: ", change.doc.data());
                        if (change.doc.data().platformLive === true) {
                            self.setState({ isBtnLive: true })
                        }
                        else if (change.doc.data().platformLive === false) {
                            self.setState({ isBtnLive: false })
                        }

                        if (change.doc.data().showProductLaunch === true) {
                            self.setState({ showProductLaunch: true })
                        }
                        else if (change.doc.data().showProductLaunch === false) {
                            self.setState({ showProductLaunch: false })
                        }
                        // }
                    }

                    // console.log(doc.id, " => ", doc.data());

                });

                // resolve(profilePicArray);

            });

    }

    isMobileDevice = () => {
        if (navigator.userAgent.match(/Android/i) ||
            navigator.userAgent.match(/webOS/i) ||
            navigator.userAgent.match(/iPhone/i) ||
            navigator.userAgent.match(/iPad/i) ||
            navigator.userAgent.match(/iPod/i) ||
            navigator.userAgent.match(/BlackBerry/i) ||
            navigator.userAgent.match(/Windows Phone/i)) {
            return true;
        } else {
            return false;
        }
    }

    goToLobby = () => {
        let str = window.location.href;
        // console.warn('string is ::', str.substring(str.lastIndexOf('/')+1))
        if (str.substring(str.lastIndexOf('/') + 1) !== 'entrance') {
            window.location.href = '/entrance'
        }
    }

    goToWebinar = () => {
        let str = window.location.href;
        if (str.substring(str.lastIndexOf('/') + 1) !== 'webinar') {
            window.location.href = '/webinar'
        }
    }

    goToBreakout = () => {
        let str = window.location.href;
        if (str.substring(str.lastIndexOf('/') + 1) !== 'breakout-page') {
            window.location.href = '/breakout-page'
        }
    }

    goToArchiveGallary = () => {
        let str = window.location.href;
        if (str.substring(str.lastIndexOf('/') + 1) !== 'archive') {
            window.location.href = '/archive'
        }
    }

    // goToCountryRooms = () => {
    //     let str = window.location.href;
    //     if (str.substring(str.lastIndexOf('/') + 1) !== 'country') {
    //         window.location.href = '/country'
    //     }
    // }

    goToBreakTimeZone = () => {
        let str = window.location.href;
        if (str.substring(str.lastIndexOf('/') + 1) !== 'entertainment-zone') {
            window.location.href = '/entertainment-zone'
        }
    }

    goToDisplayGallarySection = () => {
        let str = window.location.href;
        if (str.substring(str.lastIndexOf('/') + 1) !== 'display') {
            window.location.href = '/product-launch'
        }
    }

    goToSalesRoom = () => {
        let str = window.location.href;
        if (str.substring(str.lastIndexOf('/') + 1) !== 'live-chat') {
            window.location.href = '/live-chat'
        }
    }

    goToMedexFaculty = () => {
        let str = window.location.href;
        if (str.substring(str.lastIndexOf('/') + 1) !== 'medex-faculty') {
            window.location.href = '/faculty-room'
        }
    }

    goToAgenda = () => {
        document.getElementById('location1').style.display = 'block'
    }

    goToInfo = () => {
        document.getElementById('info1').style.display = 'block'
    }

    goToFeedback = () => {
        console.log("Feedback link");

        // this.setState({ shouldShowNotification: true, notificationMsg: "Feedback Link for Event" });
        // setTimeout(() => {
        //     this.setState({ shouldShowNotification: false });
        // }, 6000);

        let myUid = localStorage.getItem('uid') ? localStorage.getItem('uid') : "ZUEFqTqgEuDGrkz";
        window.open(`/feedback.html#${myUid}`, "_blank");

        // window.open('https://boehringeringelheim.eu.qualtrics.com/jfe/form/SV_6EagGCEAT29mqpf?event_name=META_ILD_VIRTUAL_MASTERCLASS_2020&country_code=MC', '_blank')
        // window.open('https://boehringeringelheim.eu.qualtrics.com/jfe/form/SV_6EagGCEAT29mqpf?event_name=MEDEX_2021_&country_code=MC', '_blank')
    }

    userLogout = () => {


        console.warn('update last scene end time')
        const db = analyticsFirebase.firestore();
        db.collection("LiveAttendees").doc(localStorage.getItem('analyticsDocRef')).update({
            endTime: new Date().getTime(),
        })
            .then(function (docRef) {
                console.log("Document written with ID: ", localStorage.getItem('analyticsDocRef'));
                // currentDoc = docRef;

                localStorage.clear()
                window.location.href = "/"
            })
            .catch(function (error) {
                console.error("Error adding document: ", error);
            });






        // authFirebaseApp.auth().signOut().then(res => {
        //     console.log('log out')
        // })
        // this.props.goToPage(PAGETYPE.ENTERTAINMENT);
    }
    nurseAgenda = () => {
        document.getElementById('nurseAgenda').style.display = 'block'
        document.getElementById('agendaNurseBtn').setAttribute('class', 'btnBlueGreen active');
        document.getElementById('agendaNeurologistBtn').setAttribute('class', 'btnBlueGreen margin');
        document.getElementById('neurologistAgenda').style.display = 'none'
    }

    neurologistAgenda = () => {
        document.getElementById('neurologistAgenda').style.display = 'block'
        document.getElementById('agendaNeurologistBtn').setAttribute('class', 'btnBlueGreen margin active');
        document.getElementById('agendaNurseBtn').setAttribute('class', 'btnBlueGreen');
        document.getElementById('nurseAgenda').style.display = 'none'
    }

    closePopup = () => {
        document.getElementById('location1').style.display = 'none'
    }

    changeTab = (tabNum) => {
        if (document.getElementById('tab-1').classList.contains('tab-current')) {
            document.getElementById('tab-1').classList.remove('tab-current');
            document.getElementById('tabHeader1').classList.remove('tab-active');
        }

        if (document.getElementById('tab-2').classList.contains('tab-current')) {
            document.getElementById('tab-2').classList.remove('tab-current');
            document.getElementById('tabHeader2').classList.remove('tab-active');
        }
        document.getElementById(`tab-${tabNum}`).classList.add('tab-current');
        document.getElementById(`tabHeader${tabNum}`).classList.add('tab-active');
    }

    infoButtonclose = () => {
        document.getElementById('info1').style.display = 'none'
    }

    changeAgendaDate = (activeTabNum) => {
        console.warn('Should open tab', activeTabNum);
        for (let i = 1; i <= 11; i++) {
            document.getElementById('tab-' + i).style.display = 'none';

            if (document.getElementById('tab-' + i).classList.contains('tab-current')) {
                document.getElementById('tab-' + i).classList.remove('tab-current');
            }

            if (document.getElementById('btn-' + i).classList.contains('tab-active')) {
                document.getElementById('btn-' + i).classList.remove('tab-active');
            }
        }
        document.getElementById('tab-' + activeTabNum).style.display = 'block';
        document.getElementById('tab-' + activeTabNum).classList.add('tab-current');
        document.getElementById('btn-' + activeTabNum).classList.add('tab-active');
    }

    startVideo = () => {
        this.setState({ isVideoStart: true })
    }

    closeVideo = () => {
        this.setState({ isVideoStart: false })
    }


    playVideo1 = () => {
        if (document.getElementById('2dVideo1') &&
            document.getElementById('Pause2dVideo1') &&
            document.getElementById('Play2dVideo1')) {
            document.getElementById('2dVideo1').play();
            document.getElementById('2dVideo1').volume = 1;
            document.getElementById('Pause2dVideo1').style.display = 'block';
            document.getElementById('Play2dVideo1').style.display = 'none';
            localStorage.setItem('isVideoWatched', 'true')
            // document.getElementById('countdown1').style.display = 'block';

        }
    }

    pauseVideo1 = () => {
        if (document.getElementById('2dVideo1') &&
            document.getElementById('Pause2dVideo1') &&
            document.getElementById('Play2dVideo1')) {
            document.getElementById('2dVideo1').pause();

            document.getElementById('2dVideo1').volume = 0;
            document.getElementById('Play2dVideo1').style.display = 'block'
            document.getElementById('Pause2dVideo1').style.display = 'none'
            // document.getElementById('countdown1').style.display = 'none';
        }
    }
    openbottommenu = () => {
        document.getElementById("bottommenu").classList.remove("bottomaniamtion");
        document.getElementById("Mobmenu").style.display = "none";
        document.getElementById("Mobmenuclose").style.display = "flex";
        if ((document.getElementById("myScrollingChat"))) {

            document.getElementById("myScrollingChat").classList.remove("ishiddenchat");
        }
        if ((document.getElementById("Chat"))) {

            document.getElementById("Chat").classList.add("chaticonposition2");
        }
        if (document.getElementById("quickAccessBtn")) {
            document.getElementById("quickAccessBtn").classList.remove("btn-hover-shinebottom");
        }

    }
    closebottommenu = () => {
        document.getElementById("bottommenu").classList.add("bottomaniamtion");
        document.getElementById("Mobmenu").style.display = "flex";
        document.getElementById("Mobmenuclose").style.display = "none";
        if ((document.getElementById("Chat"))) {

            document.getElementById("Chat").classList.remove("chaticonposition2");
        }
        if (document.getElementById("quickAccessBtn")) {
            document.getElementById("quickAccessBtn").classList.add("btn-hover-shinebottom");
        }
        // if (document.getElementById("BOTTTOMchatbox"))
        //     document.getElementById("BOTTTOMchatbox").classList.add("bottomchatbox1");
        // if (document.getElementById("message-form"))
        //     document.getElementById("message-form").classList.add("chat-textnormalani");

    }



    render() {
        return (
            <div>
                {this.state.isVideoStart &&
                    <div>
                        <div className="overlayVideo" id="VideoOverlay1" >
                            {/* <div id="countdown1" style={{ display: 'none', position: 'fixed', top: '30px', width: '70px', textAlign: 'center', left: '50%', transform: 'translate(-50%, -50%)', color: 'white', padding: '3px 10px', border: '2px solid white', borderRadius: '5px' }}> <span></span> </div> */}

                        </div>


                        <div id="video-popup1">
                            <button id="closeBtn" className="closebtnposition" onClick={this.closeVideo} style={{ outline: 'none' }}>
                                <img src={closeBtn} alt="play" className="closebtnwidth"></img>
                            </button>
                            <p className="videoTitle">Guided Tour by Emma</p>
                            <video id="2dVideo1" className="videopopup" playsInline crossOrigin="anonymous" src={entranceVideo} poster={videoPosterImg} preload="auto" ></video>
                            <button id="Play2dVideo1" onClick={this.playVideo1} style={{ outline: 'none', border: '0', background: 'transparent' }}>
                                <img src={playImg} alt="play" className="playbtnwidth"></img>
                                <p className="playvideotext">Play Guided Tour</p>
                            </button>
                            <button id="Pause2dVideo1" style={{ display: 'none', outline: 'none', border: '0', background: 'transparent' }} onClick={this.pauseVideo1} >
                                <img src={pauseImg} alt="play" className="playbtnwidth"></img>
                                <p className="playvideotext">Pause Guided Tour</p>

                            </button>
                            {/* <button id="startExperienceButton1" style={{ display: 'none' }} className="startExperianceBtn heightExperiance" onClick={this.startExperiance} >Enter Event</button> */}


                        </div>
                    </div>}
                {this.state.isBtnLive && localStorage.getItem('Location') !== 'webinar-hall' &&
                    <button id="quickAccessBtn" onClick={this.goToWebinar} className="btn-hover-shine btn-glow btn-hover-shinebottom" >Quick Access to Live Session</button>
                }

                {/* INFO POPUP */}
                <div className="modal" id="info1" >
                    <div className="modal-dialog modal-dialog-centered" >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title green-text" id="staticBackdropLabel">Info</h5>

                                <button type="button" className="close black-color" data-dismiss="modal" onClick={() => this.infoButtonclose()}>&times;</button>
                            </div>
                            <div className="modal-body">

                                {
                                    (this.isMobileDevice()) ?
                                        <img src={tutorialimg2} style={{ width: '100%' }} />
                                        :
                                        <img src={tutorialimg1} style={{ width: '100%' }} />
                                }


                            </div>
                        </div>
                    </div>
                </div>
                {/* INFO POPUP */}
                <div className="mobmenu" id="Mobmenu">
                    <img src={menuImg} className="widthmenuicon" alt="" onClick={this.openbottommenu} />
                </div>
                <div className="mobmenuclose" id="Mobmenuclose">
                    <img src={closeImg} className="widthmenuicon" alt="" onClick={this.closebottommenu} />
                </div>
                <div id="bottommenu" className="bottomaniamtion">
                    <ul className="list-unstyled  flexicon scrolltypewhite">
                        <li className="flexcolumn" onClick={this.goToLobby}>
                            <img src={lobbyImg} className="widthicon" alt="Lobby" />
                            <span className="cenetrtext bottomtext">Lobby</span>
                        </li>

                        <li className="flexcolumn" onClick={this.goToWebinar}>
                            <img src={webinarImg} className="widthicon" alt="Webinar" />
                            <span className="cenetrtext bottomtext">Webinar Hall</span>
                        </li>

                        {/* comment by bhavik */}
                        {/* {this.state.showProductLaunch && */}
                            <li className="flexcolumn" onClick={this.goToDisplayGallarySection}>
                                <img src={displayGallery} className="widthicon" alt="Gallary" />
                                <span className="cenetrtext bottomtext">Product Launch</span>
                            </li>
                        {/* } */}
                        <li className="flexcolumn" onClick={this.goToSalesRoom}>
                            <img src={salesImg} className="widthicon" alt="Gallary" />
                            <span className="cenetrtext bottomtext">Sales Booth</span>
                        </li>

                        {/* comment by bhavik */}
                        <li className="flexcolumn" onClick={this.goToFeedback} >
                            <img src={feedbackImg} className="widthicon" alt="Feedback" />
                            <span className="cenetrtext bottomtext">Feedback</span>

                        </li>
                        <li className="flexcolumn"  >
                            <img src={logout} className="widthicon" alt="Log out" onClick={this.userLogout} />
                            <span className="cenetrtext bottomtext">Log Out</span>

                        </li>
                    </ul>
                </div>

                {this.state.shouldShowNotification && <CustomNotification msg={this.state.notificationMsg} />}

            </div>
        )
    }
}
