import React, { Component } from 'react'
import VideoInDialog from '../display-gallary/video-in-dialog';
import Footer from '../footer/footer-page'
import authFirebaseApp from '../functions/features/auth-firebase-app';
import { updateLastSceneEndTime } from '../functions/helpers/analytics-function';
import Header from '../header/header'
import dbFirebaseApp from '../functions/features/db-firebase-app';
import loginFirebaseApp from '../functions/features/login-firebase-app';

const expoName = 'udyog-launch';
const roomName = 'archiveGallery'
const sceneName = 'archiveGallery'
const archiveGalleryIframe = 'https://angles-expo.firebaseapp.com/dekho/609e4b3935124100127081cc/3/novr/none'
export default class ArchiveGallary extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            videoUrl: '',
            isDialogOpen: false,
            showProductLaunch: false
        })
        // this.sendMessage = this.sendMessage.bind(this);
    }

    componentDidMount() {
        // authFirebaseApp.auth().onAuthStateChanged((user) => {
        //     if (user) {
        //         console.warn('user archive', user)
        //         // ...
        //     }
        //     else {
        //         console.warn('user not found')
        //         window.location.href = '/'
        //         // User is signed out
        //         // ...
        //     }
        // });
        if (localStorage.getItem('userRole') === 'user' && localStorage.getItem('uid') !== '') {
            console.warn('welcome')
        } else {
            window.location.href = '/'
        }
        localStorage.setItem('Location', 'archive-gallery')
        window.addEventListener("message", this.projectButtonClick, false);
        setTimeout(() => {
            let iframeEl = document.getElementById('archiveGalleryProject');
            if (iframeEl) {
                const userInfo = {
                    name: localStorage.getItem('name'),
                    mobileno: localStorage.getItem('mobile'),
                    email: localStorage.getItem('email'),
                    expo: expoName
                }
                iframeEl.contentWindow.postMessage(userInfo, '*');
            }
        }, 4000);
        updateLastSceneEndTime(roomName)
        // this.updateCollection()
        this.getUserDetails();
    }

    getUserDetails() {
        let self = this;
        const db = loginFirebaseApp.firestore();
        const userCollection = db.collection('LiveEvents');
        // let ExistingUserQuery = new Promise((resolve, reject) => {
        userCollection.where('expo', '==', expoName)
            .onSnapshot(function (querySnapshot) {
                querySnapshot.docChanges().forEach(function (change) {
                    if (change.type === "added" && change.type !== "modified") {
                        console.log("New city: ", change.doc.data());
                        self.setState({ showProductLaunch: change.doc.data().showProductLaunch })
                    }
                    if (change.type === "modified") {
                        console.log("Modified city: ", change.doc.data());

                        self.setState({ showProductLaunch: change.doc.data().showProductLaunch })
                        
                        // }
                    }

                    // console.log(doc.id, " => ", doc.data());

                });

                // resolve(profilePicArray);

            });

    }

    // updateCollection = () => {
    //     const db = dbFirebaseApp.firestore();
    //     db.collection("MetaILDLiveUser").doc(localStorage.getItem('docRef')).update({
    //         sceneName: sceneName,
    //     })
    //         .then(function (docRef) {
    //             console.log("Document written with ID: ", docRef);
    //             // currentDoc = docRef;
    //         })
    //         .catch(function (error) {
    //             console.error("Error adding document: ", error);
    //         });
    // }


    componentWillUnmount() {
        window.removeEventListener("message", this.projectButtonClick, false);
    }



    projectButtonClick = (e) => {
        console.log('called on lobby arrow', e.data);
        if (e.data.isImageClicked) {

            if (e.data.mslKey.includes('expo.virtualpropexpo2020.com/exterior')) {
                window.location.href = '/exterior'
            } else if (e.data.mslKey.includes('expo.virtualpropexpo2020.com/webinar')) {
                window.location.href = '/webinar'
            } else if (e.data.mslKey.includes('expo.virtualpropexpo2020.com/productlaunch')) {
                if (this.state.showProductLaunch) {
                    window.location.href = '/product-launch'
                } else {
                    document.getElementById('overlayThankyou').style.display = 'block'
                    document.getElementById('thankyouPopup').style.display = 'block'
                }
            }
        }
        if (e.data.isLinkClicked) {
            if (e.data.url) {
                if (e.data.url.includes('youtube.com')) {
                    this.setState({
                        videoUrl: e.data.url,
                        isDialogOpen: true,
                    })

                } else {
                    window.open(e.data.url, '_blank');
                }
                // const form = document.createElement("form");
                // form.setAttribute('method', "post");
                // form.setAttribute('target', '_blank');
                // form.setAttribute('action', e.data.url);
                // document.body.append(form);
                // form.submit();
            }
        }
    }

    onClose = () => {
        this.setState({
            isDialogOpen: false
        })
    }

    closeThankyouPopup=() => {

        document.getElementById('overlayThankyou').style.display = 'none'
        document.getElementById('thankyouPopup').style.display = 'none'
    }


    render() {
        return (
            <div>
                <Header headerType="user" />
                <iframe id="archiveGalleryProject" className="iframe-position" title="VR background"
                    allowvr="yes" scrolling="no" src={archiveGalleryIframe}>
                </iframe>
                <VideoInDialog url={this.state.videoUrl} isOpen={this.state.isDialogOpen} onClose={this.onClose} />
                <Footer />

                {/* <!-- overlay --> */}
                <div className="overlay" id="overlayThankyou" onClick={this.closeThankyouPopup}>
                </div>
                {/* <!-- register successful popup  --> */}
                <div className="modal " id="thankyouPopup" style={{ display: 'none', textAlign: 'center' }}>
                    <div className="modal-dialog modal-dialog-centered modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" onClick={this.closeThankyouPopup} className="close"
                                    data-dismiss="modal">x</button>
                            </div>
                            <div className="modal-body">
                                <h3 className="color-green centertext" style={{ marginBottom: "20px !important;" }}><strong>You'll have the access to Product Launch Room after the LIVE event.
                                </strong></h3>
                                <p className="color-green centertext">Please attend the LIVE event in the Webinar hall.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
